import packageVersion from "../../../package.json";

export const environment = {
    production: false,
    isLocal: false,
    apiServer: {
        host: 'https://api-dev.bigvu.tv/',
        version: 'v0.5/',
    },
    firebase: {
        apiKey: "AIzaSyC6sNBk9vhvGk3sz8AAU5k4hf4khAU8r-U",
        authDomain: "app.bigvu.tv"
    },
    tiktok: {
        clientId: "awok16gtpl9fta6l",
        redirectUrl: {
            origin: "https://develop.bigvu.tv",
            path: "auth/callback",
        },
    },
    linkedin: {
        clientId: "77ju366b5wzol3",
        liveClientId: "86s8gdw1jo75gg",
        redirectUrl: {
            origin: "https://develop.bigvu.tv",
            path: "auth/callback",
        },
    },
    googleApi: {
        redirectUrl: {
            origin: "https://develop.bigvu.tv",
            path: "auth/callback",
        },
        apiKey: "AIzaSyCxXQavTpcHrz9wYipjk-a5PSIuC5qcbOI",
        clientId: "310606674603-7ihhorcd4ov0533t973ma8nivgf7ii5r.apps.googleusercontent.com",
        scope: "https://www.googleapis.com/auth/youtubepartner https://www.googleapis.com/auth/youtube.force-ssl"
    },
    facebookApi: {
        version: 'v19.0',
        appId: "2406545966259984",
    },
    globals: {
        websocket: "wss://web-socket-v4-dev.bigvu.tv:6550",
        stripe: "pk_test_JJLNzmPvx8PA2nzWSUqtynzq",
        amplitudeAPIKey: "d318d7a87e73dc1dfaf811983a18776e",
        amplitudeSearchLink: "https://analytics.amplitude.com/bigvu-develop/project/212641/search/",
        qlikUserLink: "https://bigvu.us.qlikcloud.com/sense/app/c86a527f-671c-476d-a54e-c0db38bb69bb/sheet/ab704c3a-9f34-4e5d-a67f-9f97e408653c/state/analysis/options/clearselections/select/user_id/",
        host: "./api/",
        version: "v0.5/",
        debug: true,
        clientId: "Rzquwu6cANTgT4XeDHFw3ILj",
        firstAppPage: "boards",
        welcomeAppPage: "welcome",
        anonymousFirstPage: "script-story",
        maxSlideDuration: 120,
        cloudfrontAddress: "https://assets-dev.bigvu.tv",
        cdnAddress: "./cdn",
        downloadAddress: "./download",
        hlsPlaceholderUrl: "https://assets-dev.bigvu.tv/defaults/outro/playlist.m3u8",
        pagesPreviewUrl: "https://demo-site.bigvu.tv/pages/",
        pageDraftPreviewUrl: "/page/preview/",
        emptyProfileImgUrl: "https://assets-dev.bigvu.tv/defaults/user_icon.png",
        mixpanelId: "40a039d18d76a9644619bdba57947e12",
        defaultFontPreviewText: "A shining crescent far beneath the flying vessel",
        shareLink: 'https://demo-my-video.bigvu.tv/',
        orphanedAccountUrl: '/profile/preferences',
    },
    teleprompter: {
        teleprompterOrigin: 'https://develop.bigvu.tv'
    },
    collections: {
        pageBaseUrl: 'https://demo-site.bigvu.tv/collections/',
        embedWidgetBaseUrl: 'https://demo-site.bigvu.tv/collection/',
        pollVoteUrl: 'https://api-dev.bigvu.tv/v0.5/poll/vote',
        onboardingDefaultAssetId: '611cbb7cee2eb56bc16d7c2e',
        appsumoSubscriptionUrl: 'https://appsumo.8odi.net/9WELjE',
    },
    appId: {
        hotjarAppId: 698198
    },
    serverless: {
        host: 'sls.api-dev.bigvu.tv',
    },
    localTakesDatabase: {
        version: 1,
    },
    appVersion: packageVersion.versionCode,
    version: packageVersion.version,
    shareLinkHost: "demo-my-video.bigvu.tv"
};
